/*
* App Global CSS
* ----------------------------------------------------------------------------
* Put style rules here that you want to apply globally. These styles are for
* the entire app and not just one component. Additionally, this file can be
* used as an entry point to import other CSS/Sass files to be included in the
* output CSS.
* For more information on global stylesheets, visit the documentation:
* https://ionicframework.com/docs/layout/global-stylesheets
*/

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
  font-family: 'SFProDisplay';
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/SF-Pro-Display-Regular.otf');
}

@font-face {
  font-family: 'SFProDisplayBold';
  font-weight: 700;
  font-style: normal;
  src: url('./assets/fonts/SF-Pro-Display-Bold.otf');
}

@font-face {
  font-family: 'SFProDisplaySemiBold';
  font-weight: 600;
  font-style: normal;
  src: url('./assets/fonts/SF-Pro-Display-Semibold.otf');
}

.sfp400 {
  font-family: 'SFProDisplay', arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}

.sfp700 {
  font-family: 'SFProDisplayBold', arial, sans-serif;
  font-weight: 700;
  font-style: normal;
}

.sfp600 {
  font-family: 'SFProDisplaySemiBold', arial, sans-serif;
  font-weight: 600;
  font-style: normal;
}

h1 {
  font-family: 'SFProDisplayBold', arial, sans-serif;
  font-size: 8.6vw;
  text-align: center;
}

@media (min-width: 766px) {
  .text {
    font-family: 'SFProDisplay', arial, sans-serif;
    font-weight: normal;
    font-size: calc(0.8em + 1.5vw);
  }


}
.subtitle {
  font-size: calc(0.6em + 1vw);
}

.myaccount-link {
  text-decoration: none;
}

ion-col {
  padding-right: 0;
  padding-left: 0;
}

ion-card {
  padding: 0 10px;
  box-shadow: none;
  background: #FFFFFF 0 0 no-repeat padding-box;
  border-radius: 8px;
  text-align: center;
  font-family: 'SFProDisplay', arial, sans-serif;
}

ion-card-subtitle, ion-card p {
  color: #2a3148;
  text-align: center;
  font: normal normal normal 11px/13px SF Pro Text;
  letter-spacing: 0;
  opacity: 1;
}

ion-card-title {
  font-size: 28px;
  font: normal normal bold 28px/34px SF Pro Display;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
}

.tab-selected {
  ion-icon {
    filter: invert(36%) sepia(62%) saturate(4074%) hue-rotate(200deg) brightness(105%) contrast(101%);
  }

  .border-button {
    visibility: visible !important;
  }
}

h2 {
  font-family: 'SFProDisplayBold', arial, sans-serif;
  font-size: calc(1.01em + 1vw);
  margin-bottom: 0;
  text-align: center;
}

.display-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

}

.header-page {
  border: none;

  ion-toolbar {
    padding-right: 20px;
    padding-left: 20px;
    --background: transparent;
    --border-style: none;
    --min-height: 4em;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
  }
}

